<template>
	<!-- Tab bar -->
	<div id="tabbar" v-if="platform.includes('mobile') && !$store.state.menuVisible">
		<ion-button router-link="/kurse" color="white" class="tab bgwhite" :class="{'active': $route.fullPath === '/kurse' || $route.path.includes('/kurse')}">
			<div class="btn-content">
				<ion-icon :icon="listOutline"></ion-icon>
				<ion-label>Kurse</ion-label>
			</div>
		</ion-button>

		<ion-button router-link="/" color="clear" router-direction="root" class="tab center-btn">
			<div class="container">
				<div class="button">
					<ion-icon :icon="homeOutline"></ion-icon>
					<ion-label>Home</ion-label>
				</div>
			</div>
		</ion-button>

		<ion-button router-link="/news" color="white" class="tab bgwhite" :class="{'active': $route.fullPath === '/news' || $route.path.includes('/news')}">
			<div class="btn-content">
				<ion-icon :icon="newspaperOutline"></ion-icon>
				<ion-label>Neuigkeiten</ion-label>
			</div>
		</ion-button>
	</div>
</template>

<script>
import { IonIcon, IonButton, IonLabel } from '@ionic/vue';
import { listOutline, homeOutline, newspaperOutline } from 'ionicons/icons';
import { getPlatforms } from '@ionic/vue';

export default {
	name: 'TabBar',
	components: { IonIcon, IonButton, IonLabel },
	computed: {
		platform() {
			return getPlatforms();
		},
	},
	setup() {
		return { listOutline, homeOutline, newspaperOutline };
	},
};
</script>

<style lang="scss" scoped>
#tabbar {
	position: absolute;
	bottom: 0;
	right: 0;
	width: 100%;
	height: 100px;
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	color: var(--ion-color-dark);

	ion-button::part(native) {
		border-radius: 0px;
		padding: 0px;
	}

	.tab {
		display: flex;
		flex-direction: column;
		align-content: center;
		justify-content: center;
		text-align: center;
		font-size: 10px;
		width: 100%;
		text-decoration: none;
		height: 60px;
		margin: 0;
		border-radius: unset;
		position: relative;

		.btn-content {
			display: flex;
			flex-direction: column;
			color: var(--ion-color-dark);
		}

		ion-icon {
			margin: 0 auto;
			font-size: 30px;
			color: var(--ion-color-dark);
		}

		&.active {
			.btn-content {
			color: var(--ion-color-primary);
		}

		ion-icon {
			color: var(--ion-color-primary);
		}
		}
	}

	.bgwhite {
		background: white;
	}

	.center-btn {
		height: 100%;
		width: 34%;
		position: relative;
		overflow: visible;
		display: flex;

		.container {
			height: 53px;
			width: 5.5rem;
			margin-bottom: 7px;
			position: relative;
			background: white;
			align-self: flex-end;
			border-bottom-left-radius: 130px;
			border-bottom-right-radius: 130px;
			background-color: transparent;
			box-shadow: 0px 25px 0px 25px #fff;

			.button {
				background: var(--ion-color-primary);
				color: white;
				border-radius: 100%;
				height: 4.5rem;
				width: 4.5rem;
				display: flex;
				flex-direction: column;
				align-content: center;
				justify-content: center;
				position: absolute;
				margin: 0 auto;
				left: 0;
				right: 0;
				bottom: 8px;
				filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.249508));
				ion-icon {
					margin: 0 auto;
					font-size: 30px;
					color: white;
				}
			}
		}
	}
}

</style>
