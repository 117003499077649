<template>
	<ion-page>
		<PageHeader :title="'News'"></PageHeader>

		<ion-content class="inset-container">
			<ion-grid class="tabbar-b-margin">
				<ion-row>
					<ion-col
						v-for="news in allNews"
						size="12"
						size-sm="6"
						size-xl="4"
						:key="news.id"
					>
						<news-card :news="news"></news-card>
					</ion-col>
				</ion-row>
			</ion-grid>
		</ion-content>
		<TabBar></TabBar>
	</ion-page>
</template>

<script>
import { IonContent, IonPage, IonGrid, IonRow, IonCol } from '@ionic/vue';

import NewsCard from '@/components/NewsCard.vue';
import TabBar from '@/components/TabBar.vue';
import PageHeader from '@/components/PageHeader.vue';

export default {
	name: 'News',
	components: {
		IonContent,
		IonPage,
		NewsCard,
		TabBar,
		IonGrid,
		IonRow,
		IonCol,
		PageHeader,
	},
	data() {
		return {
			allNews: [],
		};
	},
	methods: {
		async ionViewWillEnter() {
			const { data } = await this.$http.get(
				'/items/news?fields=*,bild.*,kategorie.*&sort=-featured,-date_created' // &sort=sort
			);
			// Additionally sort featured by sort
			this.allNews = data.data.sort((a, b) => {
				if (a.featured && b.featured) return a.sort - b.sort;
				return true;
			});
		},
	},
};
</script>

<style lang="scss" scoped>
ion-content {
	--padding-bottom: 25px;
}
</style>
