<template>
	<ion-header :translucent="true">
		<ion-toolbar>
			<ion-buttons slot="start">
				<ion-menu-button color="black"></ion-menu-button>
			</ion-buttons>
			<ion-title class="slabs">{{ title }} <span v-if="lizenzen">({{existing}}/{{lizenzen}})</span></ion-title>
		</ion-toolbar>
	</ion-header>
</template>

<script>
import {
	IonButtons,
	IonHeader,
	IonMenuButton,
	IonTitle,
	IonToolbar,
} from '@ionic/vue';

export default {
	components: {
		IonButtons,
		IonHeader,
		IonMenuButton,
		IonTitle,
		IonToolbar,
	},
	props: {
		title: String,
		lizenzen: Number,
		existing: Number
	},
};
</script>
