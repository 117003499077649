<template>
	<ion-card
		class="card"
		:router-link="`/news/${news.id}/${$slugify(news.title)}`"
	>
		<!-- <img :src="imgLink" /> -->
		<div class="image">
			<img :src="imgLink" class="" />
			<div class="copyright" v-if="news.bild && news.bild.copyright">
				© {{ news.bild.copyright }}
			</div>
		</div>
		<ion-card-header>
			<ion-card-subtitle
				v-if="news.kategorie"
				:class="{ advertorial: news.kategorie.name === 'Advertorial' }"
				>{{ news.kategorie.name.toUpperCase() }}</ion-card-subtitle
			>
			<!-- <div>{{news.kategorie.name.toUpperCase()}}</div> -->
			<ion-card-title class="slabs">{{ news.title }}</ion-card-title>
		</ion-card-header>
		<ion-card-content>
			{{ news.short }}
		</ion-card-content>
	</ion-card>
</template>

<script>
import {
	IonCard,
	IonCardHeader,
	IonCardSubtitle,
	IonCardTitle,
	IonCardContent,
} from '@ionic/vue';

export default {
	name: 'NewsCard',
	props: {
		news: Object,
	},
	components: {
		IonCard,
		IonCardHeader,
		IonCardSubtitle,
		IonCardTitle,
		IonCardContent,
	},
	computed: {
		imgLink() {
			return `${this.$store.state.imageBase}${
				this.news.bild.id
			}?key=newscard&access_token=${localStorage.getItem('auth_token')}`;
		},
	},
};
</script>

<style lang="scss" scoped>
.card {
	cursor: pointer;

	.image {
		position: relative;
	}
	.copyright {
		position: absolute;
		bottom: 3px;
		left: 0px;
		font-size: 12px;
		padding: 0.15em 0.75em;
		background: rgba(0, 0, 0, 0.4);
		color: #ffffff;
	}

	ion-card-subtitle {
		font-weight: 100;
		font-size: 12px;
		&.advertorial {
			background-color: var(--ion-color-tertiary);
			display: inline !important;
			padding-left: 2px;
			padding-right: 2px;
		}
	}

	ion-card-title {
		font-weight: bold;
	}

	ion-card-content {
		color: var(--ion-color-dark);
	}
}
</style>
